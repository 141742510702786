<template>
  <div>
    <b-card bg-variant="light" header="Emisiones Atmosféricas" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-calderas"
                        label="Tiene calderas, hornos, otros"
                        label-for="calderas"
                    >
                        <b-form-select 
                            id="calderas" 
                            v-model="form.calderas" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-otra_fuente_emision"
                        label="Nombre o descripción de otra fuente"
                        label-for="otra_fuente_emision"
                    >
                        <b-form-input
                            id="otra_fuente_emision"
                            v-model="form.otra_fuente_emision"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-numero_fuentes"
                        label="Num. de fuentes fijas de emisión"
                        label-for="numero_fuentes"
                    >
                        <b-form-input
                            id="numero_fuentes"
                            type="number"
                            v-model="form.numero_fuentes"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-horas_operacion"
                        label="Num. horas operacion fuentes emision"
                        label-for="horas_operacion"
                    >
                        <b-form-input
                            id="horas_operacion"
                            v-model="form.horas_operacion"
                            type="number"
                            min="0"
                            max="24"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-tipo_combustible"
                        label="Tipo de combustible "
                        label-for="tipo_combustible"
                    >
                        <b-form-select 
                            id="tipo_combustible" 
                            v-model="form.tipo_combustible" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">Diesel</b-form-select-option>
                            <b-form-select-option value="1">Gas</b-form-select-option>
                            <b-form-select-option value="2">Electrica</b-form-select-option>
                            <b-form-select-option value="3">Otros</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.tipo_combustible==3">
                    <b-form-group
                        id="group-otro_combustible"
                        label="Descripcion otro tipo de combustible"
                        label-for="otro_combustible"
                    >
                        <b-form-input
                            id="otro_combustible"
                            v-model="form.otro_combustible"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-consumo_combustible"
                        label="Consumo combustible"
                        label-for="consumo_combustible"
                    >
                        <b-form-input
                            id="consumo_combustible"
                            v-model="form.consumo_combustible"
                            type="number"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-cap_instalada"
                        label="Capacidad instalada (BHP)"
                        label-for="cap_instalada"
                    >
                        <b-form-input
                            id="cap_instalada"
                            v-model="form.cap_instalada"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-sist_control"
                        label="Tiene sistema de Control de Emisiones"
                        label-for="sist_control"
                    >
                        <b-form-select 
                            id="sist_control" 
                            v-model="form.sist_control" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4" v-if="form.sist_control==1">
                    <b-form-group
                        id="group-nombre_sist_control"
                        label="Nombre del sistema de control"
                        label-for="nombre_sist_control"
                    >
                        <b-form-input
                            id="nombre_sist_control"
                            v-model="form.nombre_sist_control"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-altura_chimeneas"
                        label="Altura de las chimeneas"
                        label-for="altura_chimeneas"
                    >
                        <b-form-input
                            id="altura_chimeneas"
                            type="number"
                            max='99.9'
                            step="0.1"
                            v-model="form.altura_chimeneas"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-req_permiso_emi"
                        label="Requiere permiso Emisiones atmosféricas"
                        label-for="req_permiso_emi"
                    >
                        <b-form-select 
                            id="req_permiso_emi" 
                            v-model="form.req_permiso_emi" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.req_permiso_emi==1" >
                    <b-form-group
                        id="group-num_resolucion_emi"
                        label="Núm. Resolucion Emisiones atmosféricas"
                        label-for="num_resolucion_emi"
                    >
                        <b-form-input
                            id="num_resolucion_emi"
                            v-model="form.num_resolucion_emi"
                            :disabled="verDetalles?true:false"
                            :required="form.req_permiso_emi==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4" v-if="form.req_permiso_emi==1">
                    <b-form-group
                        id="group-archivo_emisiones"
                        label="Archivo licencia emisiones atmos."
                        label-for="archivo_emisiones"
                       
                    >
                        <b-form-file
                            id="archivo_emisiones"
                            :state="Boolean(form.archivo_emisiones)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenEmisiones" 
                            v-if=" !cargadoEmisiones"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoEmisiones" @click="descargarPDF(form.archivo_emisiones, 'emisiones')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-estudio_isocinetico"
                        label="Presenta estudio isocinetico"
                        label-for="estudio_isocinetico"
                    >
                        <b-form-select 
                            id="estudio_isocinetico" 
                            v-model="form.estudio_isocinetico" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.estudio_isocinetico==1">
                    <b-form-group
                        id="group-lab_estudio_isocinetico"
                        label="Nom. Laboratorio que realizó el estudio"
                        label-for="lab_estudio_isocinetico"
                    >
                        <b-form-input
                            id="lab_estudio_isocinetico"
                            v-model="form.lab_estudio_isocinetico"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>
        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'EmisionesAtmosfericas',
    data() {
      return {
        editar:false,
        cargadoEmisiones: false,
        form: {
            id: null,
            calderas: null,
            otra_fuente_emision: null,
            numero_fuentes: null,
            horas_operacion: null,
            tipo_combustible: null,
            otro_combustible:null,
            consumo_combustible: null,
            cap_instalada: null,
            sist_control: null,
            nombre_sist_control: null,
            altura_chimeneas: null,
            req_permiso_emi: null,
            num_resolucion_emi: null,
            archivo_emisiones: null,
            estudio_isocinetico: null,
            vigencia_estudio: null,
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarEmisionesAtmosfericas(this.visitaEnGestion.id)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultEmisionesAtmosfericas', 'emisionesAtmosfericasCargada' ]),
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearEmisionesAtmosfericas', 'cargarEmisionesAtmosfericasById', 'editarEmisionesAtmosfericas']),

        async cargarEmisionesAtmosfericas(id){
            this.activarLoading(true)
            try{
                await this.cargarEmisionesAtmosfericasById(id)
                console.log('esto es lo cargado', this.emisionesAtmosfericasCargada[0])
                if(this.emisionesAtmosfericasCargada.length>0){
                    this.editar=true
                    this.form=this.emisionesAtmosfericasCargada[0]
                    this.cargadoEmisiones =  this.form.archivo_emisiones?true:false
                    
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        imagenEmisiones(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo)
        },

        crearBase64(file){
            if(file){
                let reader = new FileReader();
                reader.onload=(e)=>{
                    let archivo = e.target.result
                    let base = archivo.split('base64,')
                    let pdf = base[1]

                    this.form.archivo_emisiones = pdf;
                }
                reader.readAsDataURL(file)
            }else{
                this.form.archivo_emisiones = null;
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Emisiones Atmosféricas, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                emision: this.form
            }

             try{
                 if(this.editar){
                     await this.editarEmisionesAtmosfericas(dataGuardar)
                 }else{
                     await this.crearEmisionesAtmosfericas(dataGuardar)
                 }
                if(this.resultEmisionesAtmosfericas.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarEmisionesAtmosfericas(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
        

        descargarPDF(pdf, nombre) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `licencia_${nombre}.pdf`;
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
    }
  }
</script>

<style scoped>
    
</style>