const ERRORES_CODIGO = [ 
    {
        codigo: "23503",
        message: "No es posible el borrado del registro, tiene registros asociados"
    },
    
]

const mostrarError =(ctx, item) =>{
    ctx.$bvModal.msgBoxOk(item.message, {
        title: 'Error',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
        })
        .then(value => {
            console.log(value)
        })
        .catch(err => {
            console.log(err)
        })
}

export const encontrarError = (ctx, e) =>{
    return ERRORES_CODIGO.find(item=>{
        if(item.codigo == e.codigo){
            mostrarError(ctx, item)
        }else{
            mostrarError(ctx, e)
        }
    })
}

